@use 'mixins' as *;

.layout-topbar {
  @apply fixed left-0 top-0 z-[998] flex h-16 w-full items-center bg-[color:var(--surface-card)] px-2 py-0 shadow transition-[left] duration-[var(--layout-section-transition-duration)] md:px-8;
}
.layout-topbar .layout-topbar-logo-container {
  @apply flex w-auto items-center gap-2 md:w-80 md:gap-12;
}
.layout-topbar .layout-topbar-logo {
  @apply inline-flex items-center gap-2 rounded-[var(--content-border-radius)] text-2xl font-medium text-[color:var(--text-color)];
}
.layout-topbar .layout-topbar-logo svg {
  @apply w-12;
}
.layout-topbar .layout-topbar-logo:focus-visible {
  @include focused();
}
