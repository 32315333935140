/* You can add global styles to this file, and also import other style files */
@use './tailwind.css';

@use './assets/layout/layout.scss';

@use 'primeicons/primeicons.css';

/* OWN STYLES */
.m-base {
  @apply block w-full rounded-lg bg-none p-0 shadow-none md:bg-white md:p-8 md:shadow;
}

.m-base-form {
  @apply block w-full rounded-lg bg-none p-0 shadow-none md:bg-white md:p-8 md:shadow lg:w-3/4 xl:w-4/6;
}

.m-base-flex {
  @apply flex w-full flex-col gap-5 rounded-lg bg-none p-0 shadow-none md:bg-white md:p-8 md:shadow;
}

/* ANGULAR CALENDAR STYLES */

.cal-month-view {
  @apply rounded-b-lg;
}

.cal-month-view .cal-header:first-child {
  @apply border-l;
}

.cal-month-view .cal-header:last-child {
  @apply border-r;
}

.cal-month-view .cal-days {
  @apply rounded-bl-lg rounded-br-lg;
}

.cal-month-view .cal-days > div:last-child .cal-cell-row {
  @apply rounded-bl-lg rounded-br-lg;
}

.cal-month-view .cal-day-badge {
  @apply bg-primary;
}

.cal-month-view .cal-day-cell {
  @apply min-h-32 overflow-hidden text-ellipsis;
}

.cal-month-view .cal-events {
  @apply min-h-min flex-col flex-nowrap items-start gap-1 leading-tight;
}

.cal-month-view .cal-event {
  @apply m-0 block h-auto w-full rounded p-1;
}

.cal-month-view .cal-cell-top {
  @apply min-h-16 flex-none;
}

.cal-week-view {
  @apply rounded-b-lg border-none;
}

.cal-week-view .cal-day-headers {
  //@apply rounded-t-lg;
  //border-top: solid 1px #e1e1e1;
}

.cal-week-view .cal-day-headers .cal-header:last-child {
  //@apply rounded-tr-lg;
}

.cal-week-view .cal-time-events {
  @apply rounded-b-lg;
}

.cal-week-view .cal-hour-odd:last-child {
  @apply rounded-b-lg;
}

.cal-month-view .cal-open-day-events {
  @apply grid grid-cols-1 gap-2 bg-gray-200 shadow-inner shadow-gray-400 md:grid-cols-3 lg:grid-cols-4;
}

.cal-open-day-events > span:nth-child(-n + 2) {
  @apply hidden;
}

.cal-month-view .cal-open-day-events .cal-event {
  @apply inline-block h-3 w-3;
  position: unset;
}

.cal-month-view .cal-event-title {
  @apply text-primary;
}

.cal-week-view .cal-event-title {
  @apply text-ellipsis text-wrap;
}

/* PRIME NG STYLES */

.p-breadcrumb {
  @apply rounded-lg border-0 bg-white p-4 shadow;
}

.p-panel-title {
  @apply text-xl font-normal;
}

.p-tabpanels {
  @apply p-0 pt-4;
}

.p-drawer-header {
  padding: unset !important;
}

// https://github.com/primefaces/primeng/issues/17687
.p-datatable-mask.p-overlay-mask {
  position: absolute;
}

.p-treetable .p-treetable-header {
  padding: unset !important;
}

.p-treetable.p-treetable-gridlines .p-treetable-thead > tr > th {
  border-width: 0 1px 0 1px !important;
}

.p-treetable-hoverable-rows .p-treetable-tbody > tr {
  cursor: unset !important;
}

.p-accordion {
  @apply rounded-lg border;

  .p-accordionpanel .p-accordioncontent-content {
    @apply mt-3;
  }

  .p-accordionpanel:last-child {
    @apply rounded-lg border-b-0;

    .p-accordioncontent-content {
      @apply rounded-lg border-b-0;
    }
  }
  .p-accordionpanel:not(.p-disabled).p-accordionpanel-active > .p-accordionheader {
    @apply bg-primary-50;
  }

  .p-accordionpanel .p-accordionheader {
    @apply text-2xl font-semibold uppercase;

    .p-iconwrapper {
      @apply text-primary;
    }
  }
}
